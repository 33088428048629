import React from 'react';
// import logo from './logo.svg';
import './App.css';
import logo from './logo_serco.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          This website is under construction.
        </p>
        <img height={170} src={logo} />
      </header>
    </div>
  );
}

export default App;
